<template>
  <!-- 电销AI机器人 -->
  <div class="">
    <head-nav></head-nav>
    <!--  头部导航   -->
    <div>
      <header class="index1">
        <!-- <div class="backgroundImg">
          <div class="backgroundImgAll" style="">
            <div class="backgroundImg1">电销AI机器人</div>
            <div class="backgroundImg2">
              基于语音识别、语音合成、自然语言理解等技术应用，赋予产品“听得慌、说得清”式的智能人机交互体验，为企业在多种实际应用场景下，实现精准识别用户意图，自动应答，自助办理业务，用科技解放人力，提高企业运营效率、降低人工成本。
            </div>
            <div class="backgroundImg3">
              <span class="backgroundImg3-1" @click="changesize">立即购买</span>
            </div>
          </div>
        </div> -->
        <div class="ceshitupian" style="width:100%;display: flex;justify-content: center;">
            <img class="imgceshi" src="../assets/06产品-企讯云/banner@2x.jpg" style="width:1920px;height:100%;object-fit:fill;">
        </div>
      </header>
    </div>

    <div class="ceshitupian" style="width:100%;display: flex;justify-content: center;">
        <img class="imgceshi" src="../assets/06产品-企讯云/jiqiren.jpg" style="width:1920px;height:100%;object-fit:fill;">
    </div>
    <footer-top></footer-top>
    <Footer></Footer>
  </div>
</template>

<script>
import HeadNav from "@/components/headNav.vue";
import footerTop from "@/components/footerTop.vue";
import Footer from "@/components/footer.vue";
import { Swiper, Navigation, Pagination, Autoplay, Scrollbar } from "swiper";
Swiper.use([Navigation, Pagination, Autoplay, Scrollbar]);
import eventBus from '../config/eventBus.js'
export default {
  name: "",
  components: {
    HeadNav,
    footerTop,
    Footer,
  },
  data() {
    return {
      industryList: [
        {
          id: 0,
          titel: "应用领域",
        },
        {
          id: 1,
          titel: "企业服务",
        },
        {
          id: 2,
          titel: "零售行业",
        },
        {
          id: 3,
          titel: "教育行业",
        },
        {
          id: 4,
          titel: "装修行业",
        },
        {
          id: 5,
          titel: "地产公司",
        },
        {
          id: 6,
          titel: "金融行业",
        },
        {
          id: 7,
          titel: "医美行业",
        },
        {
          id: 8,
          titel: "休闲娱乐行业",
        },
      ],
      flag2: 0,
      Myswiper2: null,
    };
  },
  mounted() {
    var that = this;
    // this.timeChange();
    that.Myswiper2 = new Swiper(".swiper-container2", {

      direction: "vertical",
      navigation: false,
      on: {
        transitionEnd: function () {
          that.flag2 = this.activeIndex;
          // console.log(that.flag2);
        },
      },
    });
  },
  beforeDestroy() {
    // clearInterval(this.timer);
  },
  methods: {
    changesize(){
      eventBus.$emit('add',true)
    },
    changeIndustry(e) {
      // console.log(e,'654')
      this.flag2 = e;
      this.Myswiper2.slideTo(e, 1000, false);
    },
  },
};
</script>

<style src="@/style/QiXunCloudAi.css" scoped></style>
<style lang="scss" scoped>
.swiper-container2 {
  width: 960px;
  height: 600px;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
  .swiper-wrapper {
    .swiper-slide {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
